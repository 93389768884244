var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:("selectaria_" + _vm.uId),staticClass:"selectaria",class:[
    { selectaria_error: _vm.isError, selectaria_success: !_vm.isError && _vm.selectVal } ]},[(_vm.label)?_c('div',{staticClass:"selectaria__label",domProps:{"innerHTML":_vm._s(_vm.label)}}):_vm._e(),_c('v-select',{staticClass:"selectaria__select",attrs:{"searchable":_vm.searchable,"options":_vm.items,"label":"label","clearable":false,"deselectFromDropdown":_vm.multiple,"closeOnSelect":!_vm.multiple,"reduce":function (item) { return item.value; },"multiple":_vm.multiple,"placeholder":_vm.placeholder,"disabled":_vm.disabled},on:{"input":_vm.change,"open":_vm.fixOpenScroll,"option:selected":function($event){return _vm.optionTriggered($event, true)},"option:deselecting":function($event){return _vm.optionTriggered($event, false)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
  var searching = ref.searching;
return [(searching)?[_vm._v(" "+_vm._s(_vm.searchEmpty)+" ")]:[_vm._v(" Список пуст ")]]}},(_vm.selectVal.length && !_vm.disabled)?{key:"selected-option-container",fn:function(ref){
  var option = ref.option;
return [_c('span',[_vm._v(_vm._s(option.label)+_vm._s(_vm.selectVal.length > 1 ? ", " : ""))])]}}:(_vm.selectVal.length && _vm.disabled)?{key:"selected-option-container",fn:function(ref){return [_c('span',[_vm._v(_vm._s(_vm.placeholder))])]}}:(!_vm.selectVal.length)?{key:"selected-option-container",fn:function(ref){return [_c('span',[_vm._v(_vm._s(_vm.placeholder))])]}}:null,{key:"search",fn:function(ref){
  var attributes = ref.attributes;
  var events = ref.events;
return [(!_vm.selectVal.length)?_c('div',[_vm._v(_vm._s(_vm.placeholder))]):_vm._e(),_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"maxlength":"1"}},'input',attributes,false),events))]}},{key:"list-header",fn:function(){return [_vm._t("list-header")]},proxy:true},(!_vm.disabled)?{key:"open-indicator",fn:function(ref){
  var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('svg',{attrs:{"width":"12","height":"12","viewBox":"0 0 12 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"clip-path":"url(#clip0_172_2288)"}},[_c('path',{attrs:{"d":"M10.7195 2.8447L5.9998 7.56436L1.28012 2.8447C1.21063 2.77436 1.12792 2.71846 1.03675 2.6802C0.945571 2.64195 0.847733 2.6221 0.748859 2.6218C0.649985 2.6215 0.552027 2.64076 0.460621 2.67845C0.369216 2.71615 0.286166 2.77155 0.216252 2.84147C0.146337 2.91138 0.0909369 2.99443 0.0532382 3.08584C0.0155385 3.17724 -0.00371429 3.2752 -0.00341389 3.37408C-0.00311444 3.47295 0.0167334 3.57079 0.0549862 3.66196C0.09324 3.75314 0.149143 3.83585 0.219481 3.90534L5.46948 9.15534C5.61013 9.29599 5.80089 9.375 5.9998 9.375C6.19871 9.375 6.38947 9.29599 6.53012 9.15534L11.7801 3.90534C11.9194 3.7644 11.9972 3.57408 11.9966 3.37596C11.996 3.17784 11.917 2.988 11.7769 2.84791C11.6368 2.70781 11.447 2.62884 11.2489 2.62824C11.0507 2.62764 10.8604 2.70546 10.7195 2.8447Z","fill":"#3C4242"}})]),_c('defs',[_c('clipPath',{attrs:{"id":"clip0_172_2288"}},[_c('rect',{attrs:{"width":"12","height":"12","fill":"white","transform":"matrix(4.37114e-08 -1 -1 -4.37114e-08 12 12)"}})])])])])]}}:null],null,true),model:{value:(_vm.selectVal),callback:function ($$v) {_vm.selectVal=$$v},expression:"selectVal"}}),(_vm.isError)?_c('div',{staticClass:"selectaria__error",domProps:{"innerHTML":_vm._s(_vm.errorText)}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }